import React from 'react'
import { Helmet } from 'react-helmet'
import SiteContainer from "../components/site-container";
import ContactForm from "../components/ContactForm";

class Contact extends React.Component {
    render() {
        return (
            <SiteContainer location={this.props.location} >
                <Helmet>
                    <title>Firstparty - Getting Started</title>
                    <meta name="description" content="Get started with Firstparty, or contact the team to learn more." />
                    <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
                </Helmet>
                <ContactForm />
            </SiteContainer>
        )
    }
}

export default Contact
