import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: false,
            first_name: "",
            last_name: "",
            email: "",
            warehouse: "postgres",
            message: ""
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFormChange(e) {
        let attr = e.target.getAttribute('name');
        this.setState({
            [attr]: e.target.value
        });
    }

    handleSubmit(e) {
        if(window.firstparty) {
            window.firstparty.track('Form Completed', {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                warehouse: this.state.warehouse,
                message: this.state.message,
            })
        }

        this.setState({
            complete: true
        })

        e.preventDefault();
    }

    render() {
        const state = this.state

        return (
            <section className="pt-9 pb-9 pt-md-12 pb-md-12 bg-light">
                <div className="container">
                    <div className="row justify-content-center pb-10">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h1>
                                Get In Touch
                            </h1>

                            <p className="lead text-gray-700">
                                We always want to hear from you! Fill out the form below and someone will be in touch as soon as
                                possible.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center gx-0">
                        <div className="col-12">
                            <Card border="primary" className="card-border shadow-light-lg">
                                <Card.Body>
                                {!state.complete ?
                                <form onSubmit={this.handleSubmit}>
                                    <Row className="mb-5">
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactName">First Name</label>
                                                <input required type="text" onChange={this.handleFormChange} value={state.first_name} name="first_name" className="form-control" id="firstname" />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactName">Last Name</label>
                                                <input required type="text" onChange={this.handleFormChange} value={state.last_name} name="last_name" className="form-control" id="lastname" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactEmail">Work Email</label>
                                                <input required type="email" onChange={this.handleFormChange} value={state.email} name="email" className="form-control" id="contactEmail"
                                                       placeholder="myname@company.com" />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactEmail">Your Data Warehouse</label>
                                                <select className="form-select" value={state.warehouse} onChange={this.handleFormChange} name="warehouse" id="warehouse">
                                                    <option value="redshift">AWS Redshift</option>
                                                    <option value="s3">AWS S3</option>
                                                    <option value="bigquery">Google BigQuery</option>
                                                    <option value="postgres">Postgres</option>
                                                    <option value="snowflake">Snowflake</option>
                                                    <option value="other">Other / None</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col>
                                            <div className="form-group mb-3">
                                                <label htmlFor="contactEmail">Message (optional)</label>
                                                <textarea onChange={this.handleFormChange} value={state.message} name="message" className="form-control" id="contactMessage"
                                                       placeholder="Question or message for the Firstparty team" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-3">
                                            <button type="submit" className="btn btn-primary lift">
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                                : <div className="row">
                                        <div className="col-12 justify-content-center">
                                            <p className="font-size-lg text-center">
                                                Thank you! We'll get back to you as soon as possible.
                                            </p>
                                        </div>
                                    </div>}
                                </Card.Body>
                            </Card>

                        </div>
                    </div>
                </div>
            </section>

        )
    }
}